import React, { useContext } from 'react';
import { Avatar, Layout as AntLayout, Popover, theme } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Nav from '../nav/nav';
import { Footer } from 'antd/es/layout/layout';
import GuestAvatarPopover from '../guest-avatar-popover/guest-avatar-popover';
import { UserContext } from '../../utils/contexts';
import AvatarPopover from '../avatar-popover/avatar-popover';
import LanguageSelector from '../language-selector/language-selector';

interface IContentAntLayoutProps {
	children: React.ReactNode;
}

const ContentAntLayout: React.FC<IContentAntLayoutProps> = ({ children }) => {
	const { user } = useContext(UserContext);

	const { Header, Sider, Content } = AntLayout;
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	return (
		<AntLayout style={{ height: '100vh', maxHeight: '100vh' }}>
			<Sider
				breakpoint="lg"
				collapsedWidth="0"
				onBreakpoint={(broken) => {
					// console.log(broken);
				}}
				onCollapse={(collapsed, type) => {
					// console.log(collapsed, type);
				}}
			>
				<div className="logo" />
				<Nav />
			</Sider>
			<AntLayout style={{ height: '100%' }}>
				<Header style={{ padding: 0 }}>
					<div style={{ display: 'flex' }}>
						<div>
							<LanguageSelector />
						</div>
						<div style={{ marginLeft: 'auto', marginRight: '2%' }}>
							<Popover
								title={user?.email || 'User Actions'}
								placement="leftTop"
								content={
									user ? (
										<AvatarPopover />
									) : (
										<GuestAvatarPopover />
									)
								}
							>
								<Avatar
									size="large"
									style={{
										backgroundColor: 'lightgrey',
										color: 'black',
									}}
									icon={<UserOutlined />}
								/>
							</Popover>
						</div>
					</div>
				</Header>
				<Content style={{ margin: '24px 16px 0', overflow: 'auto' }}>
					<div
						style={{
							padding: 24,
							minHeight: '100%',
							height: '100%',
							maxHeight: '100%',
							overflow: 'auto',
							background: colorBgContainer,
						}}
					>
						{children}
					</div>
				</Content>
				<Footer style={{ textAlign: 'center' }}>
					Ant Design ©2023 Created by Ant UED
				</Footer>
			</AntLayout>
		</AntLayout>
	);
};

export default ContentAntLayout;
