import { Button } from 'antd';
import React, { useContext } from 'react';
import { UserContext } from '../../utils/contexts';
import storageAccessor from '../../utils/storage-accessor';

const AvatarPopover = () => {
	const { setUser } = useContext(UserContext);
	const logout = () => {
		storageAccessor.setStorageItem('user', undefined);
		storageAccessor.setStorageItem('jwt', undefined);
		storageAccessor.setStorageItem('tokens', undefined);
		setUser(undefined);
	};

	return (
		<>
			<div>
				<Button type="link" onClick={() => logout()}>
					Logout
				</Button>
			</div>
		</>
	);
};

export default AvatarPopover;
