import { Button, ButtonProps } from 'antd';
import React from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

interface INavigationButtonProps {
	direction: 'back' | 'forward';
	buttonProps?: ButtonProps;
}

const navigationButtonParentStyle: React.CSSProperties = {
	height: '100%',
};

const NavigationButton: React.FC<INavigationButtonProps> = (props) => {
	return (
		<div style={navigationButtonParentStyle}>
			<Button {...props.buttonProps} style={navigationButtonParentStyle}>
				{props.direction === 'back' ? (
					<ArrowLeftOutlined />
				) : (
					<ArrowRightOutlined />
				)}
			</Button>
		</div>
	);
};

export default NavigationButton;
