import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import storageAccessor from '../../common/utils/storage-accessor';
import { ITokenResponse } from '../../common/models/responses/token-response';

export const requestInterceptor = (
	config: AxiosRequestConfig
): AxiosRequestConfig => {
	const tokens: ITokenResponse = storageAccessor.getStorageItem('tokens');

	if (!config.headers) config.headers = {};
	if (tokens && tokens.idToken)
		(config.headers as any)['Authorization'] = `${tokens.idToken}`;

	if ((config.url ?? '').includes('https')) {
		config.baseURL = '';
	} else {
		config.url = (process.env.REACT_APP_API_HOST ?? '') + config.url;
	}
	return config;
};

export const responseSuccessInterceptor = (
	info: AxiosResponse<any>
): AxiosResponse<any> => {
	const { config, status } = info;
	if (
		config?.url !== '/api/user/me' &&
		status === 200 &&
		config?.method !== 'GET' &&
		config?.method !== 'get'
	) {
		toast.success('Action completed!');
	}
	return info;
};

export const responseFailInterceptor = (error: any) => {
	if (error.message === 'Network Error' && !error.response) {
		toast.error(
			'Could not establish a connection between the client and API'
		);
		throw error.message;
	}
	const { status, data } = error.response;
	if (status === 500) {
		const url = `/500`;
		window.location.replace(url);
	} else if (status === 401) {
		if (!window.location.pathname.includes('/auth/login')) {
			toast.warning(`Please re-authenticate!`);
			const loginUrl = process.env.REACT_APP_IDENTITY_LOGIN_URL;
			const client = process.env.REACT_APP_IDENTITY_CLIENT_ID;
			const redirectUrl = encodeURI(
				process.env.REACT_APP_IDENTITY_REDIRECT_URL ?? ''
			);
			const url = `${loginUrl}?client=${client}&redirectUrl=${redirectUrl}`;
			window.location.replace(url);
		}
	} else if (
		status === 403 &&
		!window.location.pathname.includes('/auth/login')
	) {
		const url = `/403`;
		window.location.replace(url);
	} else {
		if (data?.errors) {
			data.errors.forEach((error: { key: string; message: string }) => {
				toast.error(`${error.key} - ${error.message}`);
			});
		} else {
			toast.error('An unexpected error has occured');
		}
	}
	throw error.response;
};
