import { Select } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSettingsContext } from '../../utils/contexts';

const LanguageSelector = () => {
	const { userSettings, setUserSettings } = useContext(UserSettingsContext);
	const { t } = useTranslation('home');

	const [languages] = useState([
		{ value: 'en', label: t('english') },
		{ value: 'nl', label: t('dutch') },
	]);

	const changeLanguage = (value: any) => {
		setUserSettings({ ...userSettings, language: value });
	};

	return (
		<div style={{ backgroundColor: 'white' }}>
			<Select
				defaultValue={userSettings.language}
				style={{ width: 120 }}
				bordered={false}
				options={languages}
				onChange={changeLanguage}
			/>
		</div>
	);
};

export default LanguageSelector;
