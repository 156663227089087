import { Menu } from 'antd';
import React from 'react';
import {
	HomeOutlined,
	GlobalOutlined,
	NumberOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Nav = () => {
	const navigate = useNavigate();
	// const navigate = (path: string) => {
	// 	router.('/users');
	// };

	return (
		<Menu
			theme="dark"
			mode="inline"
			defaultSelectedKeys={['0']}
			items={[
				{
					key: '0',
					icon: <HomeOutlined />,
					label: 'Home',
					onClick: () => navigate('/'),
				},
				{
					key: '1',
					icon: <NumberOutlined />,
					label: 'Planets',
					onClick: () => navigate('planets'),
				},
				{
					key: '2',
					icon: <GlobalOutlined />,
					label: 'Results',
					onClick: () => navigate('/results'),
				},
			]}
		/>
	);
};

export default Nav;
