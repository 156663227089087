import { Button, Result } from 'antd';
import React from 'react';

const Page500 = () => {
	return (
		<Result
			status="500"
			title="500"
			subTitle="Sorry, There is an error on our side"
			extra={<Button type="primary">Back Home</Button>}
		/>
	);
};

export default Page500;
