interface ISessionStorageItem {
	key: string;
	data: any;
}

interface StorageConfig {
	storageType: 'local' | 'session';
}

const setStorageItem = (key: string, data: any, config?: StorageConfig) => {
	const item: ISessionStorageItem = {
		key: key,
		data: data,
	};

	if (config && config.storageType === 'local') {
		localStorage.setItem(key, JSON.stringify(item));
	} else {
		sessionStorage.setItem(key, JSON.stringify(item));
	}
};

const getStorageItem = (key: string, config?: StorageConfig): any => {
	let sessionItem = undefined;
	if (config && config.storageType === 'local') {
		sessionItem = localStorage.getItem(key);
	} else {
		sessionItem = sessionStorage.getItem(key);
	}
	if (!sessionItem) return undefined;
	const item: ISessionStorageItem = JSON.parse(sessionItem);
	return item.data;
};

export default {
	setStorageItem,
	getStorageItem,
};
