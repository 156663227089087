import React, { useEffect } from 'react';
import { ITokenResponse } from '../../common/models/responses/token-response';
import storageAccessor from '../../common/utils/storage-accessor';
import WindowLoader from '../../common/components/window-loader/window-loader';

const SignedIn = () => {
	function delay(time: number) {
		return new Promise((resolve) => setTimeout(resolve, time));
	}
	useEffect(() => {
		// this is ridiculous but without this delay the token is written so quickly to local storage
		// that when the app.tsx useEffect triggers to get user the token is already ready, thus already making
		// a get user call before the refresh, and then again after the refresh
		delay(1000).then(() => {
			const searchParams = new URLSearchParams(window.location.search);
			const token = searchParams.get('token');
			const expires = searchParams.get('expires');

			if (token && expires) {
				const expiresDate = new Date(0);
				expiresDate.setUTCSeconds(+expires);
				console.log(expiresDate);
				const tokens: ITokenResponse = {
					idToken: token,
					expires: expiresDate,
				};
				storageAccessor.setStorageItem('tokens', tokens);
				window.location.replace(
					`${window.location.protocol}//${window.location.host}/`
				);
			} else {
				throw new Error('token broken');
			}
		});
	}, []);

	return (
		<>
			<WindowLoader />
		</>
	);
};

export default SignedIn;
