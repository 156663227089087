import React, { useState } from 'react';
import IResult from '../../common/models/result';
import CaptureComponent from './capture-component';
import NavigationButton from './components/navigation-button';
import { Typography } from 'antd';

interface ICaptureResultsProps {
	results: IResult[];
}

const containerDivStyle: React.CSSProperties = {
	height: '100%',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
};

const parentDivStyle: React.CSSProperties = {
	height: '100%',
	width: '100%',
	display: 'flex',
};

const headingStyle: React.CSSProperties = {
	marginTop: 0,
};

const CaptureResults: React.FC<ICaptureResultsProps> = (props) => {
	const [selectedResult, setSelectedResult] = useState(0);
	const [results, setResults] = useState<IResult[]>(props.results);

	const back = () => {
		if (selectedResult !== 0) setSelectedResult(selectedResult - 1);
	};

	const forward = () => {
		if (selectedResult !== results.length - 1)
			setSelectedResult(selectedResult + 1);
	};

	return (
		<div style={containerDivStyle}>
			<Typography.Title style={headingStyle} level={3}>
				{results.filter((it) => it.id === selectedResult)[0].title}
			</Typography.Title>
			<div style={parentDivStyle}>
				<NavigationButton
					buttonProps={{
						onClick: back,
						disabled: selectedResult === 0,
					}}
					direction="back"
				/>
				<CaptureComponent
					result={
						results
							.sort((a, b) => b.id - a.id)
							.filter((it) => it.id === selectedResult)[0]
					}
					setResult={(result) => {
						setResults([
							...results.filter((it) => it.id !== selectedResult),
							result,
						]);
						forward();
					}}
				/>
				<NavigationButton
					buttonProps={{
						onClick: forward,
						disabled: selectedResult === results.length - 1,
					}}
					direction="forward"
				/>
			</div>
		</div>
	);
};

export default CaptureResults;
