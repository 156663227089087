/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { IAuthSettings } from '../models/auth-settings';
import { IUser } from '../models/user';
import { IUserSettings } from '../models/user-settings';

interface IUserContext {
	user: IUser | undefined;
	setUser: (user: IUser | undefined) => void;
}
export const UserContext = createContext<IUserContext>({
	user: undefined,
	setUser: () => {},
});

interface IUserSettingsContext {
	userSettings: IUserSettings;
	setUserSettings: (settings: IUserSettings) => void;
}
export const UserSettingsContext = createContext<IUserSettingsContext>({
	userSettings: {
		language: 'en',
	},
	setUserSettings: () => {},
});

interface IAuthSettingsContext {
	authSettings: IAuthSettings | undefined;
}
export const AuthSettingsContext = createContext<IAuthSettingsContext>({
	authSettings: undefined,
});
