import React from 'react';
import IResult from '../../common/models/result';
import CaptureResults from './capture-results';

const parentDivStyle: React.CSSProperties = {
	height: '100%',
	width: '100%',
};

const ResultsIndex = () => {
	const results: IResult[] = [
		{
			id: 0,
			total: 5,
			title: 'question 1',
		},
		{
			id: 1,
			total: 3,
			title: 'question 2',
		},
		{
			id: 2,
			total: 2,
			title: 'question 3',
		},
		{
			id: 3,
			total: 8,
			title: 'question 4',
		},
		{
			id: 4,
			total: 2,
			title: 'question 5',
		},
		{
			id: 5,
			total: 15,
			title: 'question 6',
		},
		{
			id: 6,
			total: 5,
			title: 'question 7',
		},
	];
	return (
		<div style={parentDivStyle}>
			<CaptureResults results={results} />
		</div>
	);
};

export default ResultsIndex;
