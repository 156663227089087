import React from 'react';
import IResult from '../../common/models/result';
import { Button, Col, Divider, Row } from 'antd';

interface ICaptureComponentProps {
	result: IResult;
	setResult: (result: IResult) => void;
}

const parentDivStyle: React.CSSProperties = {
	height: '100%',
	width: '100%',
};

const buttonStyle: React.CSSProperties = {
	width: '100%',
	height: '100%',
};

const CaptureComponent: React.FC<ICaptureComponentProps> = (props) => {
	const getValues = (): number[] => {
		const result: number[] = [];
		for (let index = 0; index < props.result.total; index++) {
			result.push(index + 1);
		}
		return result;
	};

	return (
		<div style={parentDivStyle}>
			<Row
				gutter={[16, 16]}
				justify="space-evenly"
				style={{ height: '100%' }}
			>
				{getValues().map((it) => {
					return (
						<Col key={it} xs={12} sm={8} md={6} lg={4} xl={3}>
							<Button
								type={
									props.result.score === it
										? 'primary'
										: 'default'
								}
								onClick={() =>
									props.setResult({
										...props.result,
										score: it,
									})
								}
								style={buttonStyle}
							>
								{it}
							</Button>
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

export default CaptureComponent;
