import { Button } from 'antd';
import React from 'react';

const GuestAvatarPopover = () => {
	const loginUrl = process.env.REACT_APP_IDENTITY_LOGIN_URL;
	const client = process.env.REACT_APP_IDENTITY_CLIENT_ID;
	const redirectUrl = encodeURI(
		process.env.REACT_APP_IDENTITY_REDIRECT_URL ?? ''
	);
	const url = `${loginUrl}?client=${client}&redirectUrl=${redirectUrl}`;

	return (
		<>
			<a href={url}>
				<Button type="link">Login</Button>
			</a>
		</>
	);
};

export default GuestAvatarPopover;
