import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'en',
		resources: {
			en: {
				translations: require('./locales/en/translations.json'),
				home: require('./locales/en/home.json'),
				components: require('./locales/en/components.json'),
			},
			nl: {
				translations: require('./locales/nl/translations.json'),
				home: require('./locales/nl/home.json'),
			},
		},
		ns: ['translations', 'home'],
		defaultNS: 'translations',
	});

i18n.languages = ['en', 'nl'];

export default i18n;
