import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import '../styles/App.css';
import Home from './home';
import ContentLayout from '../common/components/layout/content-layout';
import Page404 from './home/404';
import { UserContext, UserSettingsContext } from '../common/utils/contexts';
import { IUser } from '../common/models/user';
import storageAccessor from '../common/utils/storage-accessor';
import { IUserSettings } from '../common/models/user-settings';
import { ITokenResponse } from '../common/models/responses/token-response';
import i18n from '../i18n/config';
import Page403 from './home/403';
import Page500 from './home/500';
import WindowLoader from '../common/components/window-loader/window-loader';
import SignedIn from './auth/signed-in';
import { getUserFromJwt } from '../common/utils/jwt';
import Planets from './home/planets';
import ResultsIndex from './results';

function App() {
	const getDefaultSettings = (): IUserSettings => {
		return (
			storageAccessor.getStorageItem('settings', {
				storageType: 'local',
			}) ?? { language: 'en' }
		);
	};
	const changeLanguageHandler = (lang: string) => {
		i18n.changeLanguage(lang);
	};

	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState<IUser | undefined>(undefined);
	const [userSettings, setUserSettings] = useState<IUserSettings>(
		getDefaultSettings()
	);
	const getUser = () => {
		setLoading(true);
		const tokens: ITokenResponse = storageAccessor.getStorageItem('tokens');
		if (!tokens) {
			setLoading(false);
			return;
		} else {
			const user = getUserFromJwt();
			if (user) setUser(user);
		}
		setLoading(false);
	};

	useEffect(() => {
		changeLanguageHandler(userSettings.language);
		storageAccessor.setStorageItem('settings', userSettings, {
			storageType: 'local',
		});
	}, [userSettings]);

	useEffect(() => {
		getUser();
	}, []);

	if (loading) {
		return <WindowLoader />;
	}

	const AppLayout = () => (
		<UserContext.Provider value={{ user, setUser }}>
			<UserSettingsContext.Provider
				value={{
					userSettings,
					setUserSettings,
				}}
			>
				<ContentLayout>
					<Outlet />
				</ContentLayout>
			</UserSettingsContext.Provider>
		</UserContext.Provider>
	);

	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate to={'/home'} />} />
				<Route path="/auth">
					<Route path="signed-in" element={<SignedIn />} />
				</Route>

				<Route path="/home" element={<AppLayout />}>
					<Route index element={<Home />} />
					<Route path="planets" element={<Planets />} />
					<Route path="403" element={<Page403 />} />
					<Route path="404" element={<Page404 />} />
					<Route path="500" element={<Page500 />} />
				</Route>

				<Route path="/results" element={<AppLayout />}>
					<Route index element={<ResultsIndex />} />
				</Route>

				<Route path="/403" element={<Navigate to={'/home/403'} />} />
				<Route path="/500" element={<Navigate to={'/home/500'} />} />
				<Route path="*" element={<Navigate to={'/home/404'} />} />
			</Routes>
		</>
	);
}

export default App;
