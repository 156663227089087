import React, { useEffect, useRef, useState } from 'react';
import { IQueryableRequest } from '../../models/requests/queryable-request';
import { IQueryableResponse } from '../../models/responses/queryable-response';
import { Button, Input, InputRef, Space, Table } from 'antd';
import { OrderDirection } from '../../models/requests/queryable-order';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/es/table/interface';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import { IQueryableFilter } from '../../models/requests/queryable-filter';

interface IRestDataGridProps<TRequest, TResponse> {
	fetch: (
		request: IQueryableRequest<TRequest>
	) => Promise<IQueryableResponse<TResponse>>;
	columns: ColumnsType<TResponse>;
	request?: TRequest;
	pageSize?: number;
	pageSizeOptions?: number[];
	actions?: React.ReactNode[];
}

const RestDataGrid = <TRequest, TResponse extends object>(
	props: IRestDataGridProps<TRequest, TResponse>
) => {
	const { t } = useTranslation('components');

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<IQueryableResponse<TResponse>>({
		items: [],
		offset: 0,
		pageSize: props.pageSize ?? 10,
		totalItems: 0,
	});
	const [request, setRequest] = useState<IQueryableRequest<TRequest>>({
		offset: 0,
		pageSize: props.pageSize ?? 10,
		order: {
			orderDirection: OrderDirection.Ascending,
			propertyName: 'Id',
		},
		request: props.request,
	});
	const searchInput = useRef<InputRef>(null);

	const updatePage = (page: number, pageSize: number) => {
		setRequest({
			...request,
			pageSize: pageSize,
			offset: (page - 1) * pageSize,
		});
	};

	const sortColumn = (columnName: string, sortDirection: OrderDirection) => {
		setRequest({
			...request,
			order: {
				propertyName: columnName,
				orderDirection: sortDirection,
			},
		});
	};

	const onChange: TableProps<TResponse>['onChange'] = (
		pagination,
		filters,
		sorter,
		extra
	) => {
		// console.log('pagination', pagination);
		// console.log('filters', filters);
		// console.log('sorter', sorter);
		// console.log('extra', extra);

		if (extra.action === 'sort') {
			let field;
			let order;
			if (Array.isArray(sorter)) {
				// use the first item in the array to get the field and order properties
				field = sorter[0].field;
				order = sorter[0].order;
			} else {
				// use sorter directly to get the field and order properties
				field = sorter.field;
				order = sorter.order;
			}
			sortColumn(
				field?.toString() ?? '',
				order === 'ascend'
					? OrderDirection.Ascending
					: OrderDirection.Descending
			);
		}
	};

	const handleSearch = (
		selectedKeys: string[],
		dataIndex: string,
		close: () => void
	) => {
		close();
		const value = selectedKeys[0];
		const key = dataIndex;
		const filters: IQueryableFilter[] = request.filters ?? [];

		if (filters.filter((it) => it.propertyName === key).length > 0) {
			filters.splice(
				filters.findIndex((it) => it.propertyName === key),
				1
			);
		}
		if (value) {
			filters.push({ action: 'equals', propertyName: key, value: value });
		}

		setRequest({ ...request, filters: filters });
	};

	const handleReset = (clearFilters: () => void, dataIndex: string) => {
		clearFilters();
		setRequest({
			...request,
			filters: (request.filters ?? []).filter(
				(it) => it.propertyName !== dataIndex
			),
		});
	};

	const getColumnSearchProps = (
		dataIndex: string
	): ColumnType<TResponse> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			clearFilters,
			close,
		}) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], dataIndex, close)
					}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(
								selectedKeys as string[],
								dataIndex,
								close
							)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() =>
							clearFilters && handleReset(clearFilters, dataIndex)
						}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							close();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined
				style={{
					color:
						(request.filters?.findIndex(
							(it) => it.propertyName === dataIndex
						) ?? -1) > -1
							? '#1890ff'
							: undefined,
				}}
			/>
		),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	useEffect(() => {
		setLoading(true);
		props
			.fetch(request)
			.then((response) => {
				setData(response);
			})
			.finally(() => setLoading(false));
	}, [request]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: '1em',
				}}
			>
				<div>
					{props.actions &&
						props.actions.map((it) => {
							return it;
						})}
				</div>
				<div>
					<Button onClick={() => setRequest({ ...request })}>
						<ReloadOutlined />
					</Button>
				</div>
			</div>
			<Table
				columns={props.columns.map((it) => {
					const searchProps = getColumnSearchProps(
						it.key?.toString() ?? 'email'
					);
					return {
						...it,
						...searchProps,
					};
				})}
				dataSource={data.items}
				loading={loading}
				rowKey={'id'}
				pagination={{
					total: data.totalItems,
					pageSize: data.pageSize,
					pageSizeOptions: props.pageSizeOptions ?? [
						'10',
						'20',
						'30',
						'50',
					],
					showSizeChanger: true,
					onChange: updatePage,
					locale: { items_per_page: `/ ${t('items-per-page')}` },
				}}
				onChange={onChange}
			/>
		</>
	);
};

export default RestDataGrid;
