/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosResponse } from 'axios';
import { IQueryableRequest } from '../../common/models/requests/queryable-request';
import { IQueryableResponse } from '../../common/models/responses/queryable-response';
import {
	requestInterceptor,
	responseFailInterceptor,
	responseSuccessInterceptor,
} from './interceptors';
import IPlanet from '../../common/models/planet';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(
	responseSuccessInterceptor,
	responseFailInterceptor
);

const sleep = (ms: number) => (response: AxiosResponse) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log('slowing api request down by ' + ms);
		return new Promise<AxiosResponse>((resolve) =>
			setTimeout(() => resolve(response), ms)
		);
	} else {
		return new Promise<AxiosResponse>((resolve) => resolve(response));
	}
};

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
	get: (url: string, body?: {}) =>
		axios.get(url, { data: body }).then(sleep(1000)).then(responseBody),
	post: (url: string, body: {}) =>
		axios.post(url, body).then(sleep(1000)).then(responseBody),
	put: (url: string, body: {}) =>
		axios.put(url, body).then(sleep(1000)).then(responseBody),
	del: (url: string, body?: {}) =>
		axios.delete(url, { data: body }).then(sleep(1000)).then(responseBody),
	postForm: (url: string, body: any) => {
		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});
		return axios
			.post(url, formData, {
				headers: { 'Content-type': 'multipart/formdata' },
			})
			.then(responseBody);
	},
	postXForm: (url: string, body: any) => {
		return axios
			.post(url, body, {
				headers: {
					'Content-type': 'application/x-www-form-urlencoded',
				},
			})
			.then(responseBody);
	},
};

const Planets = {
	getPlanets: (
		request: IQueryableRequest<unknown>
	): Promise<IQueryableResponse<IPlanet>> => requests.post(`/world`, request),
};

export default {
	Planets: Planets,
};
