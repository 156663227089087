import React from 'react';
import RestDataGrid from '../../common/components/rest-datagrid/rest-datagrid';
import { getPlanets } from '../../modules/api/planet-data-handler/planet-data-handler';

const Planets = () => {
	return (
		<div>
			<RestDataGrid
				columns={[
					{
						title: 'Id',
						key: 'id',
						dataIndex: 'id',
					},
					{
						title: 'Name',
						key: 'name',
						dataIndex: 'name',
					},
					{
						title: 'Size',
						key: 'size',
						dataIndex: 'size',
					},
				]}
				fetch={getPlanets}
			/>
		</div>
	);
};

export default Planets;
