/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import './loader.css';

const WindowLoader = () => {
	return (
		<div
			className="window-loader-container"
			style={{
				zIndex: 9999,
				margin: 0,
				padding: 0,
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundImage:
					'radial-gradient(circle farthest-corner at center, #3C4B57 0%, #ffffff 100%)',
			}}
		>
			<div className="window-loader">
				<div className="window-loader-inner one"></div>
				<div className="window-loader-inner two"></div>
				<div className="window-loader-inner three"></div>
			</div>
		</div>
	);
};

export default WindowLoader;
