import { ITokenResponse } from '../models/responses/token-response';
import { IUser } from '../models/user';
import jwt_decode from 'jwt-decode';
import storageAccessor from './storage-accessor';

const getUserFromJwt = (): IUser | undefined => {
	const tokens: ITokenResponse = storageAccessor.getStorageItem('tokens');
	const idTokenDecoded: any = jwt_decode(tokens.idToken);
	const expires = new Date(0);
	expires.setUTCSeconds(idTokenDecoded.exp);

	if (expires < new Date()) {
		storageAccessor.setStorageItem('tokens', undefined);
		return undefined;
	}

	return {
		id: idTokenDecoded.nameid,
		email: idTokenDecoded.x_email,
		fullName: idTokenDecoded.x_fullname ?? '',
		permissions: JSON.parse(idTokenDecoded.x_permissions),
	};
};

// const isJwtExpired = () => {
// 	const tokens: ITokenResponse = storageAccessor.getStorageItem('tokens');
// 	const idTokenDecoded: any = jwt_decode(tokens.idToken);
// 	const expires = new Date(0);
// 	expires.setUTCSeconds(idTokenDecoded.exp);

// 	return expires <= new Date();
// };

export { getUserFromJwt };
