import IPlanet from '../../../common/models/planet';
import { IQueryableRequest } from '../../../common/models/requests/queryable-request';
import { IQueryableResponse } from '../../../common/models/responses/queryable-response';
import api from '../api';

const getPlanets = async (
	request: IQueryableRequest<unknown>
): Promise<IQueryableResponse<IPlanet>> => {
	return await api.Planets.getPlanets(request);
};

export { getPlanets };
